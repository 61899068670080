import { CircularProgress, SvgIconOwnProps, SvgIconTypeMap } from "@mui/material";
import useTickingTimer from "../hooks/useTickingTimer";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export type IconOrSpinnerProps = {
  waitTimeInMillis?: number,
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
} & SvgIconOwnProps;

const oneSecondInMillis = 1000

/**
 * Display an icon for a period of time, then switch to a loading spinner.
 * This is useful for IconButton components, where the icon should be displayed for a short time before the loading spinner is shown.
 * @see IconButtonWithTooltip for an example of usage.
 * @see SingleClickWaitingButton for similar functionality (`Button`s not `Icon`s).
 * @see LoadingSpinner for a standalone loading spinner.
 */
const IconOrSpinner: React.FC<IconOrSpinnerProps> = ({ waitTimeInMillis, icon, ...iconProps }) => {
  const IconComponent = icon

  return useTickingTimer(waitTimeInMillis ?? oneSecondInMillis)
    ? <CircularProgress size={24} />
    : <IconComponent {...iconProps} />
}

export default IconOrSpinner;

import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import { useSelectedCustomer } from '../routes/CustomerSelector'
import AppBarBox from './AppBarBox'

const MenuAppBar: React.FC = () => {
  const selectedCustomer = useSelectedCustomer()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const auth0 = useAuth0()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClickLogout = () => {
    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
    handleClose()
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color='primary'>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            <img src="/branding/totalscreening/logo.jpeg" alt={`${process.env.REACT_APP_NAME_STUB} Logo`} style={{ marginTop: 10, marginRight: 10 }}/>
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Customer Portal
            {selectedCustomer && (
              <span style={{ marginLeft: 3 }}>
              - {selectedCustomer.customer_name}
            </span>
            )}
            <span style={{ marginLeft: '1rem' }}>
              <Link to='/'>🏠</Link>
            </span>
          </Typography>
          <AppBarBox sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} />
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={onClickLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default MenuAppBar

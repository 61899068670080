import React from "react";
import { Alert, AlertTitle, Box, Button, Container, Typography } from "@mui/material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { isSomething } from "../../utils/utils";
import { queryClient } from "../../bootstrapping/InitReactQuery";

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { customer_id, application_id } = useParams<{ customer_id?: string, application_id?: string }>()
  if (!customer_id) throw new Error('customer_id is required')
  if (!application_id) throw new Error('application_id is required')

  // Determine the cost of the application
  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id } })
  const isWithAdultFirst = getEBulkApplicationByID.data?.documents_customer?.application?.withAdultFirst ?? false

  const getJobs = apiClientHooks.useGetJobs()
  const job = getJobs.data?.rows.find(j => j.id === getEBulkApplicationByID.data?.job_id)
  const isStandard = job?.application_type === 'standard'
  const isEnhanced = job?.application_type === 'enhanced'
  const isVolunteer = job?.volunteer === true

  const price = isVolunteer
    ? { costInPence: 2000, code: 'volunteer', label: 'Volunteer Application' }
    : isStandard
      ? { costInPence: 3800, code: 'standard', label: 'Standard Application' }
      : isEnhanced
        ? isWithAdultFirst
          ? { costInPence: 6880, code: 'enhanced_with_adult_first', label: 'Enhanced Application with Adult First (ISA)' }
          : { costInPence: 5800, code: 'enhanced', label: 'Enhanced Application' }
        : undefined // We should be either standard or enhanced

  // Load the Customer to determine if payment by invoice is allowed
  const getCustomers = apiClientHooks.useGetCustomers()
  const customer = getCustomers.data?.rows.find(c => c.id === customer_id)
  const canBeInvoiced = customer?.allow_pay_by_invoice ?? false

  const allowedPaymentMethods = [
    { key: 'paypal', label: 'PayPal (now)' },
    canBeInvoiced ? { key: 'invoice', label: 'Invoice (later)' } : undefined,
  ].filter(isSomething)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string | undefined>(undefined)

  const payForEBulkApplicationByID = apiClientHooks.usePayForEBulkApplicationByID({
    params: {
      id: application_id,
    },
  })

  const onPayByPayPal = async () => {
    // TODO
  }

  const onPayByInvoice = async () => {
    if (!price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method: 'invoice',
      payment_code: price.code,
      payment_pence: price.costInPence,
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/${customer_id}`)
  }

  return <>
    <Helmet>
      <title>Payment</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Payment
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3}>
            {price?.label} - £{(price?.costInPence ?? 0) / 100}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Please choose a payment method to continue:
          </Typography>
          {allowedPaymentMethods.map(method => (
            <Button
              key={method.key}
              variant='contained'
              color={selectedPaymentMethod === method.key ? 'success' : 'primary'}
              sx={{ marginBottom: 2 }}
              onClick={() => setSelectedPaymentMethod(method.key)}
            >
              {method.label}
            </Button>
          ))}
          {selectedPaymentMethod === 'paypal' && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              <AlertTitle>PayPal Payment</AlertTitle>
              We are currently unable to process PayPal payments. Please choose another payment method.
              <br />
              <br />
              <Button variant='contained' onClick={onPayByPayPal} disabled={true}>
                Pay by PayPal
              </Button>
            </Alert>
          )}
          {selectedPaymentMethod === 'invoice' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>Invoice Payment</AlertTitle>
              Please confirm that you wish for this application to be paid for by invoice.
              <br />
              <br />
              <Button variant='contained' onClick={onPayByInvoice}>
                Pay by Invoice
              </Button>
            </Alert>
          )}
        </Box>
      </Form>
    </Container>
  </>
}

export default Element

import React, { PropsWithChildren, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Box, Avatar, Typography, Alert, Link } from "@mui/material";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import { AxiosError } from "axios";
import { apiClient, apiClientHooks, useRegisterAuthTokenProvider } from "./InitApiClient";
import { queryClient } from "./InitReactQuery";
import { Helmet } from "react-helmet-async";
import ShowInvites from "../components/invites/ShowInvites";
import { useIdTokenClaims } from "../components/auth0/useIdTokenClaims";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import LoadingSpinner from "../components/LoadingSpinner";
import { schemas } from "../generated/api/client";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";

export const useGetMyProfileWithJwt = () =>
  apiClientHooks.useGetMyProfile({
    queries: {
      jwt: true,
    }
  }, {
    retry: (failureCount, error) => {
      if (error instanceof AxiosError && error.response?.status === 404) {
        console.debug('Missing Profile (404) - no profile!')
        return false
      }

      return failureCount < 3
    },
  })

export const refetchProfileJwt = () =>
  queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })

const RegisterAuthTokenProvider: React.FC = () => {
  const getMyProfile = useGetMyProfileWithJwt()

  useRegisterAuthTokenProvider(
    'profile',
    async () => {
      const jwt = getMyProfile.data?.jwt
      if (!jwt) throw new Error('Profile JWT not yet set')
      return jwt
    },
    [getMyProfile.data?.jwt],
  )

  return null
}

export const useProfileAssured = () => {
  const getMyProfile = useGetMyProfileWithJwt()
  if (!getMyProfile.data) throw new Error('Profile not yet initialised')
  return getMyProfile.data
}

export const useProfileJwt = () => useGetMyProfileWithJwt().data?.jwt

const useLogoutLink = () => {
  const auth0 = useAuth0()

  return async () => {
    await auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }
}

const CreateProfile: React.FC = () => {
  const {
    givenName,
    familyName,
  } = useIdTokenClaims()

  const { idToken } = useIdTokenClaims()

  const onCreateProfile = async () => {
    // Create a profile, using the given name and family name from the Auth0 token (if it exists)
    // Missing fields are defaulted to empty strings
    const response = await apiClient.createMyProfile({
      given_name: givenName ?? '',
      family_name: familyName ?? '',
    }, {
      headers: { 'x-auth0-id-token': idToken ?? '' },
    })
    console.log("[CreateProfile] Create Response: ", response)

    // TODO: Check that the creation was correct

    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })
  }

  // Automatically create a profile
  useEffect(() => {
    if (idToken !== undefined) {
      console.debug('[CreateProfile] Creating a profile...')
      onCreateProfile()
    } else {
      console.debug('[CreateProfile] No ID Token yet...')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idToken])

  return null
}

const zUpdateProfileFormSchema = schemas.createMyProfile_Body
type UpdateProfileFormSchema = z.infer<typeof zUpdateProfileFormSchema>

const UpdateProfileIfNameMissing: React.FC = () => {
  // Build the Form
  const form = useForm<UpdateProfileFormSchema>({
    resolver: zodResolver(zUpdateProfileFormSchema),
    mode: 'onChange',
  })

  const onUpdateProfile = async () => {
    await apiClient.updateMyProfile({
      given_name: form.getValues('given_name'),
      family_name: form.getValues('family_name'),
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyProfile') })
  }

  const textFieldElementCommonProps = {
    control: form.control,
    margin: "normal" as const,
    fullWidth: true,
  }

  return <>
    <Helmet>
      <title>Profile Name</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h3" marginBottom={3}>
          Update Profile
        </Typography>
        <Typography variant="body1">
          Please tell us your name:
        </Typography>
          <TextFieldElement {...textFieldElementCommonProps} label="Forename" name="given_name" />
          <TextFieldElement {...textFieldElementCommonProps} label="Surname" name="family_name" />
          <SingleClickWaitingButton
            onClick={onUpdateProfile}
            variant="contained"
            color="primary"
            sx={{ marginTop: 3 }}
            disabled={!form.formState.isValid}
          >
            Update Profile
          </SingleClickWaitingButton>
      </Box>
    </Container>
  </>
}

const UnauthorisedAccess: React.FC = () => {
  const {
    email,
    givenName,
  } = useIdTokenClaims()

  const onClickLogout = useLogoutLink()

  return <>
    <Helmet>
      <title>Unauthorised Access</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <PersonOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" marginBottom={3}>
          Hi {givenName}!
        </Typography>
        <Typography variant="body1" marginBottom={3}>
          This site is for <strong>{process.env.REACT_APP_NAME_STUB}</strong> customers only.
        </Typography>
        <ShowInvites
          noInvites={<>
            <Alert severity="error" sx={{ marginBottom: 3 }}>
              You do not currently have customer access.
              <br />
              <br />
              Please request a <strong>customer</strong> invite for: <strong>{email}</strong>.
            </Alert>
          </>}
        />
        <Typography variant="body1" marginBottom={1}>
          <Link onClick={onClickLogout}>logout</Link>
        </Typography>
      </Box>
    </Container>
  </>
}

const InitRequireProfile: React.FC<PropsWithChildren> = ({ children }) => {
  const getMyProfile = useGetMyProfileWithJwt()

  useEffect(() => {
    if (getMyProfile.data) {
      console.debug('Profile', {
        email: getMyProfile.data.email,
        given_name: getMyProfile.data.given_name,
        family_name: getMyProfile.data.family_name,
      })
    }
  }, [getMyProfile.data])

  if (getMyProfile.isInitialLoading) {
    return <LoadingSpinner name='InitRequireProfile' /> // We don't want to flash the create profile page while we're loading state
  }

  if (getMyProfile.data === undefined) {
    return <CreateProfile />
  }

  if (!getMyProfile.data.given_name || !getMyProfile.data.family_name) {
    return <>
      <RegisterAuthTokenProvider />
      <UpdateProfileIfNameMissing />
    </>
  }

  return <>
    <RegisterAuthTokenProvider />
    {getMyProfile.data.admin_customer_ids.length > 0
      ? <>{children}</>
      : <UnauthorisedAccess />
    }
  </>
}

export default InitRequireProfile

import React, { useMemo } from 'react';
import {
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { Alert, CircularProgress, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../../generated/api/client';
import { isSomething } from '../../utils/utils';
import { useAppTable } from '../../components/AppTable';

type CustomersWithMeta = z.infer<typeof schemas.ReturnType_GetCustomers>
type Customer = CustomersWithMeta['rows'][number]

const DetailPanel = ({ row }: { row: MRT_Row<Customer> }) => {
  const {
    data: jobExpanded,
    isLoading,
    isError,
  } = apiClientHooks.useGetCustomerExpanded(
    {
      params: {
        id: row.original.id,
      },
    },
    {
      enabled: row.getIsExpanded(),
    },
  );
  if (isLoading) return <CircularProgress />;
  if (isError) return <Alert severity="error">Error Loading User Info</Alert>;

  const { admin_profiles, applications } = jobExpanded ?? {};

  return <>
    <Stack gap="0.5rem" minHeight="00px">
      <Typography variant="h6">Customer Admins</Typography>
      {admin_profiles && admin_profiles.length > 0
        ? admin_profiles.map((profile) => (
          <div key={profile.id}>
            {profile.given_name} {profile.family_name}
          </div>
        ))
        : <div><em>No Admins</em></div>
      }

      <Typography variant="h6">Applications</Typography>
      <div>
        <b>Draft:</b> {applications?.totalDraft}
      </div>
    </Stack>
  </>;
};

export const Element: React.FC = () => {
  const {
    tableConfig,
  } = useAppTable<Customer, CustomersWithMeta, Error | null>({
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<CustomersWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getCustomers'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetCustomers.parse(compoundSort)
          })()

          return await apiClient.getCustomers({
            queries: {
              page_index: `${pagination.pageIndex}`,
              page_size: `${pagination.pageSize}`,
              sort,
              globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
              columnFilters: schemas.ColumnFilters_GetCustomers.parse(
                columnFilters
                  .map(filter => {
                    // Check ID is a valid column
                    const parseResult = schemas.ColumnFilters_GetCustomers.element.safeParse(filter)
                    if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                      console.error("Invalid column ID for filter: ", filter)
                      return undefined
                    }

                    // Transform Enum Filters

                    // Transform Boolean Filters
                    if (filter.id === 'active' || filter.id === 'allow_pay_by_invoice') {
                      const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                      if (!valueAsStringBoolean.success) {
                        console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: valueAsStringBoolean.data === 'true' ? true : false,
                      }
                    }

                    // Transform Date Range Filters
                    if (filter.id === 'created_at' || filter.id === 'updated_at') {
                      const values = z.array(z.date().optional()).safeParse(filter.value)
                      if (!values.success || values.data.length !== 2) {
                        console.error("Invalid date range filter: ", filter)
                        return undefined
                      }
                      if (values.data[0] === undefined && values.data[1] === undefined) {
                        // Nothing to filter on!
                        return undefined
                      }

                      return {
                        id: filter.id,
                        from: values.data[0]?.toISOString(),
                        to: values.data[1]?.toISOString(),
                      }
                    }

                    // Everything else is just a text filter
                    var parsedValue = z.string().safeParse(filter.value)
                    if (!parsedValue.success) {
                      console.error("Invalid text filter: ", filter)
                      return undefined
                    }

                    return {
                      id: filter.id,
                      value: parsedValue.data,
                    }
                  })
                  .filter(isSomething)
              )
            },
          })
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<Customer>[]>(() => [
      {
        accessorKey: 'name',
        header: 'Customer Name',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'pin',
        header: 'PIN',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'allow_pay_by_invoice',
        accessorFn: (originalRow) => (originalRow.allow_pay_by_invoice ? 'true' : 'false'), //must be strings
        header: 'Allow Pay By Invoice',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'sector_healthcare',
        accessorFn: (originalRow) => (originalRow.sector_healthcare ? 'true' : 'false'), //must be strings
        header: 'Healthcare Sector',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        // filterVariant: 'checkbox',
      },
      {
        accessorKey: 'enable_applicant_document_selector',
        accessorFn: (originalRow) => (originalRow.enable_applicant_document_selector ? 'true' : 'false'), //must be strings
        header: 'Applicant Documents',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        // filterVariant: 'checkbox',
      },
      {
        accessorKey: 'enable_applicant_payment',
        accessorFn: (originalRow) => (originalRow.enable_applicant_payment ? 'true' : 'false'), //must be strings
        header: 'Applicant Payment',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        // filterVariant: 'checkbox',
      },
    ], []),
  });

  const table = useMaterialReactTable({
    ...tableConfig,
    muiExpandButtonProps: ({ row }) => ({
      children: row.getIsExpanded() ? <MinusIcon /> : <AddIcon />,
    }),
    renderDetailPanel: props => <DetailPanel {...props} />,
  });

  return <>
    <Helmet>
      <title>Customers</title>
    </Helmet>
    <MaterialReactTable table={table} />;
  </>
}

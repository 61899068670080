import React, { useEffect, useMemo } from "react";
import { Container, Box, Typography, Card, CardContent, CardActions, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { useProfileAssured } from "../bootstrapping/InitRequireProfile";
import ShowInvites, { useCustomerInvites } from "../components/invites/ShowInvites";

export const useAdminCustomersWithName = (): { customer_id: string, customer_name: string }[] | undefined => {
  const profile = useProfileAssured()
  const admin_customer_ids = profile.admin_customer_ids

  // NOTE: For staff, who can see all customers, this might be a long list! (it might even page and cause problems?)
  // TODO: Consider adding a customFiler for 'my-admin-customers' set to true, which then filters based on the user's admin-customer-ids.
  const customers = apiClientHooks.useGetCustomers({}, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // 1 hour
  })

  const admin_customers = useMemo(() => {
    if (customers.data == null) return undefined
    return customers.data.rows.filter(customer => admin_customer_ids.includes(customer.id))
  }, [admin_customer_ids, customers.data])

  return admin_customers == null
    ? undefined
    : admin_customers.map(customer => {
      const customer_id = customer.id
      const customer_name = customer.name
      return { customer_id, customer_name }
    })
}

export const useSelectedCustomer = (): { customer_id: string, customer_name: string | undefined } | undefined => {
  const { customer_id } = useParams<{ customer_id: string | undefined }>()

  const customers = apiClientHooks.useGetCustomers({
    queries: {
      globalFilter: customer_id,
    },
  }, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // 1 hour
    enabled: customer_id != null,
  })

  return customer_id == null
    ? undefined
    : customers.data == null
      ? { customer_id, customer_name: undefined }
      : { customer_id, customer_name: customers.data.rows[0]?.name }
}

export const Element: React.FC = () => {
  const navigate = useNavigate()
  const onClickNavigateToCustomer = (customerId: string): React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> => event => {
    navigate(`/${customerId}/`)
    event.preventDefault()
    event.stopPropagation()
  }

  const { isInitialLoading, customerInvites } = useCustomerInvites()
  const hasCustomerInvites = isInitialLoading
    ? undefined
    : customerInvites.length > 0

  const admin_customers = useAdminCustomersWithName()

  // If we are sure we have no customer invites and there is only one customer, select it automatically
  useEffect(() => {
    if (hasCustomerInvites === false && admin_customers?.length === 1) {
      navigate(`/${admin_customers[0].customer_id}/`)
    }
  }, [hasCustomerInvites, admin_customers, navigate])

  return <>
    <Helmet>
      <title>Select Customer</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ShowInvites noInvites={<></>} />
        <Typography component="h1" variant="h5">
          Select Customer
        </Typography>
        {admin_customers?.map(({ customer_id, customer_name }, index) => {
          const onClick = onClickNavigateToCustomer(customer_id)
          return (
            <Card key={index} variant="outlined" sx={{ backgroundColor: '#e9e9e9', marginTop: 3, cursor: 'pointer' }} onClick={onClick}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {customer_name}
                </Typography>
              </CardContent>
              <CardActions>
                <Button fullWidth onClick={onClickNavigateToCustomer(customer_id)}>Select</Button>
              </CardActions>
            </Card>
          )
        })}
      </Box>
    </Container>
  </>
}
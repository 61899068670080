import { useState } from "react"
import useAbortableEffect from "./useAbortableEffect"

const useTickingTimer = (waitForMillis: number): boolean => {
  const [hasTimePassed, setHasTimePassed] = useState(false)

  useAbortableEffect(status => {
    window.setTimeout(() => {
      // If I am still mounted, after the duration has passed, update the state
      if (!status.aborted) setHasTimePassed(true)
    }, waitForMillis)
  }, [])

  return hasTimePassed
}

export default useTickingTimer

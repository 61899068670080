import { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Box, BoxProps } from "@mui/material";

type AppBarBoxContent = {
  appBarBoxContent: {
    Element?: ReactNode,
    state?: any,
  },
  setAppBarBoxContent: (content: AppBarBoxContent['appBarBoxContent']) => void,
}

const defaultContent: AppBarBoxContent = {
  appBarBoxContent: {},
  setAppBarBoxContent: () => { },
}

const AppBarBoxContentContext = createContext<AppBarBoxContent>(defaultContent);

export const AppBarBoxContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [appBarBoxContent, setAppBarBoxContent] = useState(defaultContent.appBarBoxContent);

  const dedupingSetAppBarBoxContent = useCallback((content: AppBarBoxContent['appBarBoxContent']) => {
    setAppBarBoxContent(currentContent => {
      // Make sure we don't set the same content, based on the state
      if (JSON.stringify(currentContent.state) === JSON.stringify(content.state)) {
        return currentContent
      }

      // Change to use the updated content
      return content
    })
  }, [setAppBarBoxContent])

  const context: AppBarBoxContent = {
    appBarBoxContent,
    setAppBarBoxContent: dedupingSetAppBarBoxContent,
  }

  return (
    <AppBarBoxContentContext.Provider value={context}>
      {children}
    </AppBarBoxContentContext.Provider>
  )
}

/**
 * This resets the AppBarBoxContent to default.
 * Do this whenever the page/route changes.
 * @returns A stable function to clear the AppBarBoxContent
 */
export const useClearAppBoxContent = () => {
  const { setAppBarBoxContent } = useContext(AppBarBoxContentContext);
  return useCallback(() => {
    setAppBarBoxContent(defaultContent.appBarBoxContent)
  }, [setAppBarBoxContent]);
}

/**
 * Set the AppBarBoxContent to the provided content.
 * The `content.Element` should contain the JSX to render in the AppBarBox.
 * The `content.state` is used to determine if the content has changed and should be propagated.
 * A useEffect is used to execut only when the content changes.
 * De-duping is done within the `dedupingSetAppBarBoxContent` function, based on `content.state` alone.
 */
export const useSetAppBoxContent = (content: AppBarBoxContent['appBarBoxContent']) => {
  const { setAppBarBoxContent } = useContext(AppBarBoxContentContext);
  useEffect(() => {
    setAppBarBoxContent(content)
  }, [content, setAppBarBoxContent]);
}

/**
 * The AppBarBox is a Box that is used to display content in the AppBar.
 * The content is set using the `useSetAppBoxContent` hook.
 * The content is cleared using the `useClearAppBoxContent` hook.
 */
const AppBarBox: React.FC<BoxProps> = (props) => {
  const { appBarBoxContent } = useContext(AppBarBoxContentContext);
  return (
    <Box {...props}>
      {appBarBoxContent.Element}
    </Box>
  )
}

export default AppBarBox;


import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient"
import { Alert } from "@mui/material"
import { refetchProfileJwt } from "../../bootstrapping/InitRequireProfile"
import { useIdTokenClaims } from "../auth0/useIdTokenClaims"
import SingleClickWaitingButton from "../SingleClickWaitingButton"
import LoadingSpinner from "../LoadingSpinner"
import { queryClient } from "../../bootstrapping/InitReactQuery"

export const useCustomerInvites = () => {
  const { idToken } = useIdTokenClaims()

  // Use the idToken to get my invites
  const getMyInvites = apiClientHooks.useGetMyInvites({
    headers: { 'x-auth0-id-token': idToken ?? '' },
  }, {
    enabled: !!idToken,
  })

  // If there's no data after initial loading (such as an error), default to no invites
  const invites = getMyInvites.data ?? []

  // We only care about Customer Invites
  const customerInvites = invites.filter(invite => invite.type === 'customer')

  // Handler for Accepting the invite
  const onClickAccept = (invite: { id: string }) => async () => {
    if (!idToken) throw new Error('Should not happen: no ID token!')

    const result = await apiClient.acceptInvite(undefined, {
      params: { id: invite.id },
      headers: { 'x-auth0-id-token': idToken },
    })

    if (result.accepted) {
      refetchProfileJwt()
      queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getMyInvites') })
      queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getCustomers') })
    }
  }

  return {
    idToken,
    isInitialLoading: getMyInvites.isInitialLoading,
    customerInvites,
    onClickAccept,
  }
}

const ShowInvites: React.FC<{ noInvites: React.ReactNode }> = ({ noInvites }) => {
  const {
    idToken,
    isInitialLoading,
    customerInvites,
    onClickAccept,
  } = useCustomerInvites()

  if (isInitialLoading) return <LoadingSpinner name='ShowInvites' />

  return <>
    {customerInvites.length === 0 && noInvites}
    {/* The `invite.type === 'customer'` TS hint guard is needed for some reason, otherwise `invite.customer_name` errors. */}
    {customerInvites.map(invite => invite.type === 'customer' && (
      <Alert key={invite.id} severity="success" sx={{ marginBottom: 3 }}>
        You have been invited to join as a member of {invite.customer_name}'s team.
        <br /><br />
        <SingleClickWaitingButton variant="contained" color="primary" disabled={!idToken} onClick={onClickAccept(invite)}>Accept</SingleClickWaitingButton>
      </Alert>
    ))}
  </>
}

export default ShowInvites

import React, { useMemo } from 'react';
import {
  MRT_ColumnDef,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { useNavigate } from "react-router-dom";
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../../generated/api/client';
import { isSomething } from '../../utils/utils';
import { isAxiosError } from 'axios';
import { useAppTable } from '../../components/AppTable';

type JobsWithMeta = z.infer<typeof schemas.ReturnType_GetJobs>
type Job = JobsWithMeta['rows'][number]

const DetailPanel = ({ row }: { row: MRT_Row<Job> }) => {
  const {
    data: jobExpanded,
    isLoading,
    isError,
  } = apiClientHooks.useGetJobExpanded(
    {
      params: {
        id: row.original.id,
      },
    },
    {
      enabled: row.getIsExpanded(),
    },
  );
  if (isLoading) return <CircularProgress />;
  if (isError) return <Alert severity="error">Error Loading User Info</Alert>;

  const { job, applications } = jobExpanded ?? {};

  return <>
    <Typography variant="h6">Last Checked</Typography>
    <Stack gap="0.5rem" minHeight="00px">
      {/* We've been fully checked */}
      {job?.last_checked_at && job?.last_checked_by_profile_name && <>
        <div>
          <strong>At:</strong> {job?.last_checked_at}
        </div>
        <div>
          <strong>By:</strong> {job?.last_checked_by_profile_name} (<em>{job?.last_checked_by_profile_id}</em>)
        </div>
      </>}
      {/* We've changed since last checked, or never been checked */}
      {job?.last_checked_at == null && <>
        <div>
          <em>Needs checking!</em>
        </div>
        {job?.last_checked_by_profile_id != null && <>
          <div>
            <strong>Last Checked By:</strong> {job?.last_checked_by_profile_name} (<em>{job?.last_checked_by_profile_id}</em>)
          </div>
        </>}
      </>}
    </Stack>
    <Typography variant="h6">Justifications</Typography>
    <Stack gap="0.5rem" minHeight="00px">
      <div>
        <strong>Application Workforce:</strong> {job?.application_workforce_justification}
      </div>
      <div>
        <strong>Application Type:</strong> {job?.application_type_justification}
      </div>
      <div>
        <strong>Working with Adults:</strong> {job?.working_with_adults_justification}
      </div>
      <div>
        <strong>Working with Children:</strong> {job?.working_with_children_justification}
      </div>
      <div>
        <strong>Working at Home Address:</strong> {job?.working_at_home_address_justification}
      </div>
    </Stack>
    <Typography variant="h6">Job Applications</Typography>
    <Stack gap="0.5rem" minHeight="00px">
      <div>
        <b>Draft:</b> {applications?.totalDraft}
      </div>
    </Stack>
  </>;
};

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const {
    tableConfig,
  } = useAppTable<Job, JobsWithMeta, Error | null>({
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<JobsWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getJobs'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetJobs.parse(compoundSort)
          })()

          try {
            const result = await apiClient.getJobs({
              queries: {
                page_index: `${pagination.pageIndex}`,
                page_size: `${pagination.pageSize}`,
                sort,
                globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
                columnFilters: schemas.ColumnFilters_GetJobs.parse(
                  columnFilters
                    .map(filter => {
                      // Check ID is a valid column
                      const parseResult = schemas.ColumnFilters_GetJobs.element.safeParse(filter)
                      if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                        console.error("Invalid column ID for filter: ", filter)
                        return undefined
                      }

                      // Transform Enum Filters

                      // Transform Boolean Filters
                      if (filter.id === 'active' || filter.id === 'allowPayByInvoice') {
                        const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                        if (!valueAsStringBoolean.success) {
                          console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                          return undefined
                        }

                        return {
                          id: filter.id,
                          value: valueAsStringBoolean.data === 'true' ? true : false,
                        }
                      }

                      // Transform Date Range Filters
                      if (filter.id === 'createdAt' || filter.id === 'updatedAt') {
                        const values = z.array(z.date().optional()).safeParse(filter.value)
                        if (!values.success || values.data.length !== 2) {
                          console.error("Invalid date range filter: ", filter)
                          return undefined
                        }
                        if (values.data[0] === undefined && values.data[1] === undefined) {
                          // Nothing to filter on!
                          return undefined
                        }

                        return {
                          id: filter.id,
                          from: values.data[0]?.toISOString(),
                          to: values.data[1]?.toISOString(),
                        }
                      }

                      // Everything else is just a text filter
                      var parsedValue = z.string().safeParse(filter.value)
                      if (!parsedValue.success) {
                        console.error("Invalid text filter: ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: parsedValue.data,
                      }
                    })
                    .filter(isSomething)
                )
              },
            })

            console.log("Invites.Element.queryFn: ", { result })
            return result
          } catch (error) {
            if (!isAxiosError(error)) {
              console.error("Error fetching jobs (non-Axios): ", error)
              throw error
            }

            const parsedValue = schemas.ErrorResponse.safeParse(error.response?.data)
            if (!parsedValue.success) {
              console.error("Error fetching jobs (unknown error): ", error)
              throw error
            }

            console.error("Error fetching jobs: ", parsedValue.data.error)
            return Promise.reject(parsedValue.data.error)
          }
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<Job>[]>(() => [
      {
        accessorKey: 'customer_id',
        header: 'Customer ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'customer_name',
        header: 'Customer Name',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'role',
        header: 'Job Role',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'application_workforce',
        header: 'Application Workforce',
        size: 150,
        enableSorting: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { label: 'Adults', value: 'adult_only' },
          { label: 'Children', value: 'child_only' },
          { label: 'Children and Adults', value: 'child_and_adult' },
          { label: 'Other', value: 'other' },
        ],
      },
      {
        accessorKey: 'application_type',
        header: 'Application Type',
        size: 150,
        enableSorting: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { label: 'Standard', value: 'standard' },
          { label: 'Enhanced', value: 'enhanced' },
        ],
      },
      {
        accessorKey: 'working_with_adults',
        accessorFn: (originalRow) => (originalRow.working_with_adults ? 'true' : 'false'), //must be strings
        header: 'Working with Adults',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'working_with_children',
        accessorFn: (originalRow) => (originalRow.working_with_children ? 'true' : 'false'), //must be strings
        header: 'Working with Children',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'working_at_home_address',
        accessorFn: (originalRow) => (originalRow.working_at_home_address ? 'true' : 'false'), //must be strings
        header: 'Working at Home Address',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'volunteer',
        accessorFn: (originalRow) => (originalRow.volunteer ? 'true' : 'false'), //must be strings
        header: 'Volunteer',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'with_adult_first',
        accessorFn: (originalRow) => (originalRow.with_adult_first ? 'true' : 'false'), //must be strings
        header: 'Adult First (ISA)',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
    ], []),
    renderAdditionalTopToolbarCustomActions: () => (
      <Button variant="contained" onClick={() => { navigate("add") }}>
        New Job Role
      </Button>
    ),
  })

  const table = useMaterialReactTable({
    ...tableConfig,
    muiExpandButtonProps: ({ row }) => ({
      children: row.getIsExpanded() ? <MinusIcon /> : <AddIcon />,
    }),
    renderDetailPanel: props => <DetailPanel {...props} />,
  });

  return <>
    <Helmet>
      <title>Job Roles</title>
    </Helmet>
    <MaterialReactTable table={table} />;
  </>
}

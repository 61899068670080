import React, { useMemo } from "react";
import { Container, Box, Typography, Grid, Card, CardContent, CardActions, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { useSelectedCustomer } from "../CustomerSelector";

export type DisplayCardProps = {
  title: string;
  count?: number;
  breakdown?: { title: string, count?: number, link?: string }[];
  linkList?: string;
  linkNew?: string;
}
export const DisplayCard: React.FC<DisplayCardProps> = props => {
  const navigate = useNavigate()

  const buttons = useMemo(() => {
    return <>
      {props.linkList && <Button size="small" onClick={() => props.linkList && navigate(props.linkList)}>📋 See All</Button>}
      {props.linkNew && <Button size="small" onClick={() => props.linkNew && navigate(props.linkNew)}>➕ Add New</Button>}
    </>
  }, [navigate, props.linkList, props.linkNew])

  return (
    <Card variant="outlined" sx={{ backgroundColor: '#e9e9e9' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h5" component="div">
          {props.count ?? <>&nbsp;</> /* holding the visual space while data loads */}
        </Typography>
        <Stack sx={{ marginTop: 1 }}>
          {props.breakdown?.map((item, index) =>
            !!item.link
              ? <div key={index} style={{ cursor: 'pointer' }} onClick={() => item.link && navigate(item.link)}>{item.title}: <strong>{item.count}</strong></div>
              : <div key={index}>{item.title}: <strong>{item.count}</strong></div>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        {buttons}
      </CardActions>
    </Card>
  )
}

export const Element: React.FC = () => {
  console.log("index.Element: ")

  const selectedCustomer = useSelectedCustomer()
  const linkTo = (path: string) => selectedCustomer ? `/${selectedCustomer.customer_id}/${path}` : undefined

  const getGlobalStats = apiClientHooks.useGetGlobalStats({
    queries: {
      customer_id: selectedCustomer?.customer_id,
    },
  }, {
    enabled: !!selectedCustomer?.customer_id,
  })
  const stats = getGlobalStats.data?.stats

  return <>
    <Helmet>
      <title>Administration</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          {selectedCustomer?.customer_name}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="👥 Profiles" linkList={linkTo("profiles")} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="🫅 Customers" count={stats?.customers.totalActive} linkList={linkTo("customers")} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="🧑‍🏫 Job Roles" count={stats?.jobs.totalActive} linkList={linkTo("jobs")} linkNew={linkTo("jobs/add")} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard
              title="📨 Invites"
              count={stats === undefined ? undefined : stats.invites.totalActiveStaff + stats.invites.totalActiveCustomers + stats.invites.totalActiveApplicants}
              breakdown={[
                { title: "Staff", count: stats?.invites.totalActiveStaff },
                { title: "Customers", count: stats?.invites.totalActiveCustomers },
                { title: "Applicants", count: stats?.invites.totalActiveApplicants },
              ]}
              linkList={linkTo("invites")}
              linkNew={linkTo("invites/add")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard
              title="📝 Applications"
              count={stats === undefined ? undefined : stats.applications.totalDraft + stats.applications.totalAwaitingCustomerApproval + stats.applications.totalAwaitingStaffApproval + stats.applications.totalAwaitingDBSSubmission + stats.applications.totalAwaitingDBSResponse + stats.applications.totalRejected + stats.applications.totalCompleted}
              breakdown={[
                { title: "Draft", count: stats?.applications.totalDraft, link: linkTo("applications?staus=draft") },
                { title: "Awaiting Customer Approval", count: stats?.applications.totalAwaitingCustomerApproval, link: linkTo("applications?status=awaiting_customer_approval") },
                { title: "Awaiting Payment", count: stats?.applications.totalAwaitingPayment, link: linkTo("applications?status=awaiting_payment") },
                { title: "Awaiting Staff Approval", count: stats?.applications.totalAwaitingStaffApproval, link: linkTo("applications?status=awaiting_staff_approval") },
                { title: "Awaiting DBS Submission", count: stats?.applications.totalAwaitingDBSSubmission, link: linkTo("applications?status=awaiting_submission_to_DBS") },
                { title: "Awaiting DBS Response", count: stats?.applications.totalAwaitingDBSResponse, link: linkTo("applications?status=awaiting_response_from_DBS") },
                { title: "Rejected", count: stats?.applications.totalRejected, link: linkTo("applications?status=rejected") },
                { title: "Completed", count: stats?.applications.totalCompleted, link: linkTo("applications?status=completed") },
              ]}
              linkList={linkTo("applications")}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
}
import React, { useMemo } from 'react';
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../../generated/api/client';
import { isSomething } from '../../utils/utils';
import { isAxiosError } from 'axios';
import { useAppTable } from '../../components/AppTable';

type ProfilesWithMeta = z.infer<typeof schemas.ReturnType_GetProfiles>
type Profile = ProfilesWithMeta['rows'][number]

export const Element: React.FC = () => {
  const {
    tableConfig,
  } = useAppTable<Profile, ProfilesWithMeta, Error | null>({
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<ProfilesWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getProfiles'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetProfiles.parse(compoundSort)
          })()

          try {
            const result = await apiClient.getProfiles({
              queries: {
                page_index: `${pagination.pageIndex}`,
                page_size: `${pagination.pageSize}`,
                sort,
                globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
                columnFilters: schemas.ColumnFilters_GetProfiles.parse(
                  columnFilters
                    .map(filter => {
                      // Check ID is a valid column
                      const parseResult = schemas.ColumnFilters_GetJobs.element.safeParse(filter)
                      if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                        console.error("Invalid column ID for filter: ", filter)
                        return undefined
                      }

                      // Transform Enum Filters

                      // Transform Boolean Filters
                      if (filter.id === 'active' || filter.id === 'allowPayByInvoice') {
                        const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                        if (!valueAsStringBoolean.success) {
                          console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                          return undefined
                        }

                        return {
                          id: filter.id,
                          value: valueAsStringBoolean.data === 'true' ? true : false,
                        }
                      }

                      // Transform Date Range Filters
                      if (filter.id === 'createdAt' || filter.id === 'updatedAt') {
                        const values = z.array(z.date().optional()).safeParse(filter.value)
                        if (!values.success || values.data.length !== 2) {
                          console.error("Invalid date range filter: ", filter)
                          return undefined
                        }
                        if (values.data[0] === undefined && values.data[1] === undefined) {
                          // Nothing to filter on!
                          return undefined
                        }

                        return {
                          id: filter.id,
                          from: values.data[0]?.toISOString(),
                          to: values.data[1]?.toISOString(),
                        }
                      }

                      // Everything else is just a text filter
                      var parsedValue = z.string().safeParse(filter.value)
                      if (!parsedValue.success) {
                        console.error("Invalid text filter: ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: parsedValue.data,
                      }
                    })
                    .filter(isSomething)
                )
              },
            })

            console.log("Invites.Element.queryFn: ", { result })
            return result
          } catch (error) {
            if (!isAxiosError(error)) {
              console.error("Error fetching jobs (non-Axios): ", error)
              throw error
            }

            const parsedValue = schemas.ErrorResponse.safeParse(error.response?.data)
            if (!parsedValue.success) {
              console.error("Error fetching jobs (unknown error): ", error)
              throw error
            }

            console.error("Error fetching jobs: ", parsedValue.data.error)
            return Promise.reject(parsedValue.data.error)
          }
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<Profile>[]>(() => [
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'given_name',
        header: 'Given Name',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'family_name',
        header: 'Family Name',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'is_staff',
        accessorFn: (originalRow) => (originalRow.is_staff ? 'true' : 'false'), //must be strings
        header: 'Staff Member',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
      },
      {
        accessorKey: 'admin_customer_ids',
        header: 'Admin Customer IDs',
        size: 150,
        enableSorting: false,
        // TODO: Add a filter for this, maybe a custom display
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'admin_customer_names',
        header: 'Admin Customer Names',
        size: 150,
        enableSorting: false,
        // TODO: Add a filter for this, maybe a custom display
        enableColumnFilter: false,
      },
      {
        accessorKey: 'known_customer_ids',
        header: 'Known Customer IDs',
        size: 150,
        enableSorting: false,
        // TODO: Add a filter for this, maybe a custom display
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'known_customer_names',
        header: 'Known Customer Names',
        size: 150,
        enableSorting: false,
        // TODO: Add a filter for this, maybe a custom display
        enableColumnFilter: false,
      },
    ], []),
  })

  const table = useMaterialReactTable({
    ...tableConfig,
    // muiExpandButtonProps: ({ row }) => ({
    //   children: row.getIsExpanded() ? <MinusIcon /> : <AddIcon />,
    // }),
    // renderDetailPanel: props => <DetailPanel {...props} />,
  });

  return <>
    <Helmet>
      <title>Profiles</title>
    </Helmet>
    <MaterialReactTable table={table} />;
  </>
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import InitMaterialUI from './bootstrapping/InitMaterialUI';
import InitAuth0RequiringLogin from './bootstrapping/InitAuth0RequiringLogin';
import InitRequireProfile from './bootstrapping/InitRequireProfile';
import InitApiClient from './bootstrapping/InitApiClient';
import InitReactQuery from './bootstrapping/InitReactQuery';
import InitBackendAPI from './bootstrapping/InitBackendAPI';
import { HelmetProvider } from 'react-helmet-async';
import ConsoleProvider from './components/TestingInfo';
import { AppBarBoxContentProvider } from './components/AppBarBox';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <InitMaterialUI>
        <InitAuth0RequiringLogin>
          <InitApiClient>
            <InitReactQuery>
              <InitBackendAPI>
                <ConsoleProvider>
                  <InitRequireProfile>
                    <AppBarBoxContentProvider>
                      <App />
                    </AppBarBoxContentProvider>
                  </InitRequireProfile>
                </ConsoleProvider>
              </InitBackendAPI>
            </InitReactQuery>
          </InitApiClient>
        </InitAuth0RequiringLogin>
      </InitMaterialUI>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from "react";
import { ActionFunction, redirect } from "react-router-dom";
import { apiClient, apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Alert, AlertTitle, Box, Container, Typography } from "@mui/material";
import { z } from "zod";
import { Form } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from "react-helmet-async";
import { QueryClient } from "@tanstack/react-query";
import { schemas } from "../../generated/api/client";
import JobAutocomplete from "../../components/JobAutocomplete";
import SingleClickWaitingButton from "../../components/SingleClickWaitingButton";
import { useSelectedCustomer } from "../CustomerSelector";

const zFormSchema = schemas.createInvite_Body
// Compare this against schemas.createInvite_Body
// We keep everything optional so we can do the proper checks in the superRefine
const zLocalFormSchema = z.object({
  type: z.union([z.literal("customer"), z.literal("applicant")]),
  email: z.string(),
  customer_id: z.string(),
  job_id: z.string(),
}).partial()
type LocalFormSchema = z.infer<typeof zLocalFormSchema>

export const Element: React.FC = () => {
  const selectedCustomer = useSelectedCustomer()
  const customerId = selectedCustomer?.customer_id ?? ''

  // Build the Form
  const form = useForm<LocalFormSchema>({
    resolver: zodResolver(
      zLocalFormSchema
        .superRefine((value, context) => {
          // The email is always required
          if (!value.email) {
            context.addIssue({
              code: 'custom',
              path: ['email'],
              message: 'Email is required',
            })
          }

          // If the type is customer or applicant, the customer_id must be set
          if (value.type === 'customer' || value.type === 'applicant') {
            if (!value.customer_id) {
              context.addIssue({
                code: 'custom',
                path: ['customer_id'],
                message: 'Customer is required',
              })
            }
          }

          // If the type is applicant, the job_id must be set
          if (value.type === 'applicant') {
            if (!value.job_id) {
              context.addIssue({
                code: 'custom',
                path: ['job_id'],
                message: 'Job is required',
              })
            }
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: {
      type: 'applicant',
      customer_id: customerId,
    },
  })

  const type = useWatch({ control: form.control, name: 'type' })
  const jobId = useWatch({ control: form.control, name: 'job_id' })

  const getJobById = apiClientHooks.useGetJobById({
    params: { id: jobId ?? "" },
  }, {
    enabled: !!jobId,
  })
  const job = getJobById.data
  console.log("AddInvite.Element: ", { type, jobId, job })

  // Reset the fields as appropriate when the type changes
  useEffect(() => {
    if (type === 'customer') {
      form.setValue('job_id', '')
    }
  }, [type, form])

  // Build the output
  const textFieldElementCommonProps = {
    control: form.control,
    margin: "normal" as const,
    fullWidth: true,
  }

  return <>
    <Helmet>
      <title>New Invite</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          New Invite
        </Typography>

        <Form method="post">
          <Typography variant="body1" marginBottom={3}>
            Select the type of invite you are sending:
          </Typography>

          <AutocompleteElement
            {...textFieldElementCommonProps}
            label="Type"
            name="type"
            options={['customer', 'applicant']}
            autocompleteProps={{
              disableClearable: true,
              isOptionEqualToValue: (option, value) => option === value,
              getOptionLabel: option => {
                switch (option) {
                  case 'customer': return 'Customer (Team Member)'
                  case 'applicant': return 'Applicant'
                  default: return option
                }
              }
            }}
          />

          <Typography variant="body1" marginTop={3} marginBottom={1}>
            Enter the email address of the person you would like to invite.
          </Typography>

          <input type="hidden" name="type" value={form.getValues('type')} />
          <input type="hidden" name="customer_id" value={customerId} />
          <TextFieldElement {...textFieldElementCommonProps} label="Email Address" name="email" />

          {type === 'applicant' && <>
            <Typography variant="body1" marginTop={3} marginBottom={3}>
              Select the job role for this person.
            </Typography>

            <JobAutocomplete
              control={form.control}
              label="Job Role"
              name="job_id"
              customerId={customerId}
              disabled={customerId == null || customerId === ''}
            />

            {!!jobId && <>
              <Alert severity="info" sx={{ marginTop: 3 }}>
                <AlertTitle>Job Role Settings</AlertTitle>
                Type: <strong>{job?.application_type}</strong><br />
                Workforce: <strong>{job?.application_workforce}</strong><br />
                {job?.working_at_home_address && <>✅ Working from Home Address<br /></>}
                {job?.working_with_adults && <>✅ Working with Adults<br /></>}
                {job?.working_with_children && <>✅ Working with Children<br /></>}
                {job?.with_adult_first && <>✅ With Adult First<br /></>}
                {job?.volunteer && <>✅ Volunteer<br /></>}
              </Alert>
            </>}
          </>}
          {/* If the type is applicant, select the active customer role */}

          <SingleClickWaitingButton
            type="submit"
            fullWidth
            variant="contained"
            color={form.formState.isValid ? 'primary' : 'inherit'}
            onClick={async event => {
              const isValid = await form.trigger()
              if (!isValid) {
                console.warn("Form is not valid", form.formState.errors)
                event.preventDefault()
              }
            }}
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </SingleClickWaitingButton>
        </Form>
      </Box>
    </Container>
  </>
}

export const action: (queryClient: QueryClient) => ActionFunction = queryClient => async ({ params, request }) => {
  const formData = await request.formData()
  const formObject = Object.fromEntries(formData)
  const newInviteData = zFormSchema.parse(formObject)

  const createdInvite = await apiClient.createInvite(newInviteData)
  console.log("AddInvite.action: ", { newInviteData, createdInvite })

  // Don't wait for this to complete
  queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getInvites') })
  return redirect(`/${params.customer_id}/invites`)
}

export default Element

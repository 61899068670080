import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import * as Root from './routes/Root';
import * as RootIndex from './routes/CustomerSelector';
import * as CustomerIndex from './routes/customer/Index';
import * as Profiles from './routes/customer/Profiles';
import * as Customers from './routes/customer/Customers';
import * as Jobs from './routes/customer/Jobs';
import * as AddJob from './routes/customer/AddJob';
import * as Invites from './routes/customer/Invites';
import * as AddInvite from './routes/customer/AddInvite';
import * as Applications from './routes/customer/Applications';
import * as ApplicationPayment from './routes/customer/ApplicationPayment';
import * as ApplicationMeeting from './routes/customer/ApplicationMeeting';
import * as ApplicationMeetingGroup1 from './routes/customer/ApplicationMeetingGroup1';
import * as ApplicationMeetingGroup2 from './routes/customer/ApplicationMeetingGroup2';
import * as ApplicationMeetingSummary from './routes/customer/ApplicationMeetingSummary';
import ErrorPage from './components/ErrorPage';
import { queryClient } from './bootstrapping/InitReactQuery';
import './App.css';

const router = () => createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root.Element />}
    >
      <Route
        index
        element={<RootIndex.Element />}
      />
      <Route
        path=":customer_id"
        errorElement={<ErrorPage />}
      >
        <Route
          index
          element={<CustomerIndex.Element />}
        />
        <Route
          path="profiles"
          element={<Profiles.Element />}
        />
        <Route
          path="customers"
          element={<Customers.Element />}
        />
        <Route
          path="jobs"
          element={<Jobs.Element />}
        />
        <Route
          path="jobs/add"
          element={<AddJob.Element />}
          action={AddJob.action(queryClient)}
        />
        <Route
          path="invites"
          element={<Invites.Element />}
        />
        <Route
          path="invites/add"
          element={<AddInvite.Element />}
          action={AddInvite.action(queryClient)}
        />
        <Route
          path="invites/:id/rescind"
          action={Invites.rescind(queryClient)}
        />
        <Route
          path="applications"
          element={<Applications.Element />}
        />
        <Route
          path="applications/:application_id/payment"
          element={<ApplicationPayment.Element />}
        />
        <Route
          path="applications/:application_id/meeting"
          element={<ApplicationMeeting.Element />}
        />
        <Route
          path="applications/:application_id/meeting/group1"
          element={<ApplicationMeetingGroup1.Element />}
        />
        <Route
          path="applications/:application_id/meeting/group2"
          element={<ApplicationMeetingGroup2.Element />}
        />
        <Route
          path="applications/:application_id/meeting/summary"
          element={<ApplicationMeetingSummary.Element />}
        />
      </Route>
    </Route>
  )
)

const App: React.FC = () => {
  console.log("[App] Loaded!")
  return <RouterProvider router={router()} />
}

export default App;

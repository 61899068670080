import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"

export type LoginSystem = 'Auth0' | 'Google'

export const useIdTokenClaims = () => {
  const auth0 = useAuth0()

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [givenName, setGivenName] = useState<string | undefined>(undefined)
  const [familyName, setFamilyName] = useState<string | undefined>(undefined)
  const [loginSystem, setLoginSystem] = useState<LoginSystem | undefined>(undefined)
  const [idToken, setIdToken] = useState<string | undefined>(undefined)

  useEffect(() => {
    auth0.getIdTokenClaims().then((claims) => {
      claims?.email && setEmail(claims.email);
      claims?.given_name && setGivenName(claims.given_name);
      claims?.family_name && setFamilyName(claims.family_name);
      claims?.__raw && setIdToken(claims.__raw);

      if (claims?.sub) {
        const foundLoginSystem: LoginSystem = claims.sub.startsWith('google-oauth2|')
          ? 'Google'
          : 'Auth0';

        setLoginSystem(foundLoginSystem);
      }
    })
  }, [auth0, setEmail, setGivenName, setFamilyName, setLoginSystem, setIdToken])

  return {
    email,
    givenName,
    familyName,
    loginSystem,
    idToken,
  }
}

import { ComponentProps } from "react"
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { DatePicker } from "@mui/x-date-pickers"
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form"
import * as datefns from "date-fns"
import IconPopover from "./IconPopover";

type StringFormattedControlledDatePickerProps<TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = ComponentProps<typeof DatePicker> & {
  control: Control<TFieldValues>;
  name: TName,
  format: string,
  warningPopover?: React.ReactElement,
}

const StringFormattedControlledDatePicker: React.FC<StringFormattedControlledDatePickerProps> = (props) => {
  const { control, name, format, warningPopover, ...datePickerElementProps } = props

  const convertFormattedStringToDate = (value: string | null | undefined): Date | null | undefined => {
    if (value == null) return value
    return datefns.parse(value, format, new Date())
  }

  const convertDateToFormattedString = (value: unknown): string | null | undefined => {
    if (value == null) return value

    if (value instanceof Date) {
      if (isNaN(value.getTime())) {
        // Invalid dates are converted into null
        return null
      } else {
        // Valid dates get converted into the string format
        return datefns.format(value, format)
      }
    }

    throw new Error(`Expected Date, got ${value}`)
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <DatePicker
            {...datePickerElementProps}
            ref={ref}
            value={convertFormattedStringToDate(value)}
            onChange={(value) => onChange({ target: { value: convertDateToFormattedString(value) } })}
            format={format} // Unfortuantely we can't have a separate format for display vs the input value with this approach
            minDate={new Date(1900, 0, 1)}
            maxDate={new Date()}
            slotProps={{
              textField: {
                inputProps: { name },
                error: !!error,
                helperText: error && error.message,
              }
            }}
          />
          {warningPopover && (
            <IconPopover popover={warningPopover}>
              <ReportProblemIcon data-testid="invalid-date-warning" color='warning' sx={{
                marginLeft: 2,
              }} />
            </IconPopover>
          )}
        </div>
      )}
    />
  )
}

export default StringFormattedControlledDatePicker

import { FieldPath, UseFormReturn } from "react-hook-form"
import { LocalFormSchema } from "../logic/applicationUpdateSchema"

/**
 * Takes a flattened object and returns a nested object.
 * Numeric keys are treated as array indices.
 * Text keys are treated as object keys.
 * We don't support mixed numeric and string keys at the same level.
 * @param flattened - The flattened object to unflatten.
 * @returns The unflattened nested object or array.
 */
export const unflattenObject = (flattened: Record<string, any>): Record<string, any> | Record<string, any>[] => {
  const nested: Record<string, any> = {}
  Object.entries(flattened).forEach(([key, value]) => {
    const keys = key.split('.')
    keys.reduce((acc, k, i) => {
      if (i === keys.length - 1) {
        if (/^\d+$/.test(k)) {
          acc.push(value)
        } else {
          acc[k] = value
        }
      } else {
        if (/^\d+$/.test(keys[i + 1])) {
          acc[k] = acc[k] || []
        } else {
          acc[k] = acc[k] || {}
        }
      }
      return acc[k]
    }, nested)
  })

  // If the nested keys are numeric, convert it to an array
  if (Object.keys(nested).every(k => /^\d+$/.test(k))) {
    return Object.values(nested)
  } else {
    return nested
  }
}

/**
 * Take the React Form Hook `form.formState.errors` and flatten it into a list of error messages.
 */
export const flattenErrors = (errors: any, path: string = ''): {  path: string, message: string }[] => {
  const result: {  path: string, message: string }[] = []
  for (const key in errors) {
    if (errors[key] == null) continue
    if (typeof errors[key].message === 'string') {
      result.push({
        path: path ? `${path}.${key}` : key,
        message: errors[key].message,
      })
    } else {
      result.push(...flattenErrors(errors[key], path ? `${path}.${key}` : key))
    }
  }
  return result
}

export const trimTextFields = (form: UseFormReturn<LocalFormSchema>) => {
  const trimField = (key: FieldPath<LocalFormSchema>) => {
    const value = form.getValues(key)
    if (value != null && typeof value === 'string') {
      const trimmedValue = value.trim()
      if (trimmedValue !== value) {
        form.setValue(key, trimmedValue)
        form.trigger(key)
      }
    }
  }

  const fieldsIdentity: FieldPath<LocalFormSchema>[] = [
    'AdditionalApplicantDetails.ContactNumber',
    'NiNumber',
  ]

  const fieldsNames: FieldPath<LocalFormSchema>[] = [
    'Forename',
    ...(form.getValues().Middlenames?.Middlename?.map((_, i) => `Middlenames.Middlename.${i}` as 'Middlenames.Middlename.0') ?? []),
    'PresentSurname',
    'AdditionalApplicantDetails.BirthSurname',
    ...(form.getValues().AdditionalApplicantDetails?.OtherForenames?.OtherForename.map((_, i) => `AdditionalApplicantDetails.OtherForenames.OtherForename.${i}.Name` as 'AdditionalApplicantDetails.OtherForenames.OtherForename.0.Name') ?? []),
    ...(form.getValues().AdditionalApplicantDetails?.OtherSurnames?.OtherSurname.map((_, i) => `AdditionalApplicantDetails.OtherSurnames.OtherSurname.${i}.Name` as 'AdditionalApplicantDetails.OtherSurnames.OtherSurname.0.Name') ?? []),
  ]

  const fieldsBirth: FieldPath<LocalFormSchema>[] = [
    'AdditionalApplicantDetails.BirthTown',
    'AdditionalApplicantDetails.BirthCounty',
    'AdditionalApplicantDetails.BirthNationality',
  ]

  const fieldsAddresses: FieldPath<LocalFormSchema>[] = [
    'CurrentAddress.Address.AddressLine1',
    'CurrentAddress.Address.AddressLine2',
    'CurrentAddress.Address.AddressTown',
    'CurrentAddress.Address.AddressCounty',
    'CurrentAddress.Address.Postcode',
    ...(form.getValues().PreviousAddress?.flatMap((_, i) => [
      `PreviousAddress.${i}.Address.AddressLine1` as 'PreviousAddress.0.Address.AddressLine1',
      `PreviousAddress.${i}.Address.AddressLine2` as 'PreviousAddress.0.Address.AddressLine2',
      `PreviousAddress.${i}.Address.AddressTown` as 'PreviousAddress.0.Address.AddressTown',
      `PreviousAddress.${i}.Address.AddressCounty` as 'PreviousAddress.0.Address.AddressCounty',
      `PreviousAddress.${i}.Address.Postcode` as 'PreviousAddress.0.Address.Postcode',
    ]) ?? []),
  ]

  const fieldsDocuments: FieldPath<LocalFormSchema>[] = [
    'ApplicantIdentityDetails.PassportDetails.PassportNumber',
    'ApplicantIdentityDetails.PassportDetails.PassportNationality',
  ]

  const fieldsAll: FieldPath<LocalFormSchema>[] = [
    ...fieldsIdentity,
    ...fieldsNames,
    ...fieldsBirth,
    ...fieldsAddresses,
    ...fieldsDocuments,
  ]

  fieldsAll.forEach(trimField)
}

import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom"

/**
 * When the route pathname changes, call the provided `callback` function.
 */
const useOnRouteChange = (callback: () => void) => {
  const { pathname } = useLocation();
  const oldPathname = useRef('')

  useEffect(() => {
    // Ignore if the pathname hasn't changed (it might just be the callback that has changed)
    if (oldPathname.current === pathname) return
    oldPathname.current = pathname

    // Call the callback
    console.log("Route changed!", pathname)
    callback()
  }, [oldPathname, pathname, callback])
}

export default useOnRouteChange

import { CircularProgress, CircularProgressProps } from "@mui/material";
import useTickingTimer from "../hooks/useTickingTimer";

export type LoadingSpinnerProps = {
  name: string,
  logMessage?: string,
  waitTimeInMillis?: number,
} & CircularProgressProps;

const oneSecondInMillis = 1000

/**
 * A Loading spinner. Only display a loading indicator after a short delay, to make short loads seem instantaneous.
 */
const LoadingSpinner: React.FC<LoadingSpinnerProps> = (props) =>
  useTickingTimer(props.waitTimeInMillis ?? oneSecondInMillis)
    ? <DisplayedLoadingSpinner {...props} />
    : null

const DisplayedLoadingSpinner: React.FC<LoadingSpinnerProps> = ({ name, logMessage, waitTimeInMillis, ...circularProgressProps }) => {
  if (logMessage) console.debug(`[LoadingSpinner#${name}] Spinner displayed: ${logMessage}`)

  return (
    <CircularProgress {...circularProgressProps} />
  )
}

export default LoadingSpinner;

import React, { PropsWithChildren } from "react";
import { CircularProgress } from "@mui/material";
import { apiClientHooks } from "./InitApiClient";

const oneHourInMillis = 60 * 60 * 1000

const InitBackendAPI: React.FC<PropsWithChildren> = ({ children }) => {
  const getSystemInfo = apiClientHooks.useGetSystemInfo({}, {
    staleTime: oneHourInMillis,
  })

  // TODO: Unknown how helpful this is in the error case
  if (getSystemInfo.isError) throw new Error('API Server is not available')
  if (getSystemInfo.isLoading) return <CircularProgress />

  return <>{children}</>
}

export default InitBackendAPI
import * as React from 'react';
import { ButtonProps, Button } from '@mui/material';
import useHasBeenUnmounted from '../hooks/useHasBeenUnmounted';
import LoadingSpinner from './LoadingSpinner';

type SingleClickWaitingButtonProps = {
  name?: string;
  waitTimeInMillis?: number;
} & ButtonProps;

const SingleClickWaitingButton: React.FC<SingleClickWaitingButtonProps> = ({ name, waitTimeInMillis, ...buttonProps }) => {
  /** State to disable the button when the onClick is in progress */
  const [inProgress, setInProgress] = React.useState(false)
  /** A fail-safe mutable lock which prevents the onClick logic running twice, in most race condition cases */
  const onClickRunning = React.useRef(false)

  const hasBeenUnmounted = useHasBeenUnmounted()

  const onClick: NonNullable<ButtonProps['onClick']> = async (...onClickArgs) => {
    // If there's no action, then we can short-circuit the whole dance
    if (!buttonProps.onClick) return

    // Set the In Progress flags (disabling the button)
    setInProgress(true)

    // Run the action
    if (onClickRunning.current) {
      console.warn(`[SingleClickWaitingButton#${name ?? 'unnamed'}] The onClick is already running and the button was disabled, but I still got onClicked!`)
    } else {
      onClickRunning.current = true
      await buttonProps.onClick(...onClickArgs)
      onClickRunning.current = false
    }

    // The following will trigger a react error if the button is unmounted in the onClick (such as a page change).
    // Set the In Progress flag (enabling the button)
    if (!hasBeenUnmounted.current) {
      setInProgress(false)
    } else {
      console.debug(`[SingleClickWaitingButton#${name ?? 'unnamed'}] The button was unmounted during the onClick, so I did not re-enable it.`)
    }
  }

  const disabled = buttonProps.disabled || inProgress;

  return (
    <Button {...buttonProps} disabled={disabled} onClick={onClick}>
      {inProgress && (
        <LoadingSpinner
          name={name ?? 'singleClickButton'}
          waitTimeInMillis={waitTimeInMillis}
          size={25}
          sx={{ marginRight: 1 }}
        />
      )}
      {buttonProps.children}
    </Button>
  )
}

export default SingleClickWaitingButton;

import { useEffect } from "react"

type AbortableEffectStatus = { aborted?: boolean }
type EffectCallback = (status: AbortableEffectStatus) => (void | (() => void | undefined))
type DependencyList = ReadonlyArray<unknown>

const useAbortableEffect = (effect: EffectCallback, deps?: DependencyList): AbortableEffectStatus => {
  // mutable status object
  const status: AbortableEffectStatus = {}

  // The effect that detects if we've been unmounted
  useEffect(
    () => {
      status.aborted = false
      // pass the mutable object to the effect callback
      // store the returned value for cleanup
      const cleanUpFn = effect(status)
      return () => {
        // mutate the object to signal the consumer
        // this effect is cleaning up
        status.aborted = true
        if (typeof cleanUpFn === "function") {
          // run the cleanup function
          cleanUpFn()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  // Leaking the mutatble object, as it might be useful elsewhere
  return status
}

export default useAbortableEffect
